import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Image, Modal } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import FriendlyLinks from './FriendlyLinks';
import Growth from '../images/growth4.svg';
import IconIn from '../images/icon-in.svg';
import IconZhi from '../images/icon-zhi.svg';
import IconToutiao from '../images/icon-toutiao.svg';
import IconBai from '../images/icon-bai.svg';
import IconShipin from '../images/icon-shipin.png';
import ShipinQrCode from '../images/shipin-qrcode.jpg';
import BaijiaQrCode from '../images/baijia-qrcode.jpg';
import IconInHover from '../images/icon-in-hover.svg';
import IconZhiHover from '../images/icon-zhi-hover.svg';
import IconToutiaoHover from '../images/icon-toutiao-hover.svg';
import IconBaiHover from '../images/icon-bai-hover.svg';
import IconShipinHover from '../images/icon-shipin-hover.png';
import HuijuClLogo56 from '../images/logo-white-56.svg';

const QrItem = ({ text, bindingId }) => {
  return (
    <div className="qrCode-item">
      <div className={`binding ${bindingId}`} id={bindingId} style={{ width: 96, height: 96 }} />
      <div className="title">{text}</div>
    </div>
  );
};

QrItem.propTypes = {
  text: PropTypes.string,
  bindingId: PropTypes.string,
};

const IconItem = ({ href, src, alt, qrCode, isMobile, hoverSrc = undefined }) => {
  const [visible, setVisible] = useState(false);
  const [hover, setHover] = useState(false);
  if (isMobile && qrCode) {
    return (
      <>
        <a className="icon-item" onClick={() => setVisible(true)}>
          <Image src={src} alt={alt} />
        </a>
        <Modal className="icon-item-qrModal" show={visible}>
          <div className="mobile-qr-modal-container">
            <a className="mobile-qr-modal-closer" onClick={() => setVisible(false)} />
            <Image className="icon-qr-code" src={qrCode} alt={`${alt}-qrcode`} />
          </div>
        </Modal>
      </>
    );
  }
  return (
    <a className="icon-item" href={href} target="_blank" rel="noreferrer noopener nofollow">
      {hoverSrc ? (
        <Image
          src={hover ? hoverSrc : src}
          alt={alt}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        />
      ) : (
        <Image src={src} alt={alt} />
      )}
      {qrCode && <Image className="icon-qr-code" src={qrCode} alt={`${alt}-qrcode`} />}
    </a>
  );
};

IconItem.propTypes = {
  href: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  qrCode: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default function Footer2(props) {
  return (
    <section id="footer2" className="footer2">
      {/* eslint-disable-next-line react/destructuring-assignment,react/prop-types */}
      {props.type !== 'blogs' && (
        <div className="growth-row">
          <LazyLoad>
            <Container>
              <Image src={Growth} alt="growth" style={{ marginBottom: -2 }} />
            </Container>
          </LazyLoad>
        </div>
      )}
      <div className="footer-2 pc">
        <Container>
          <div className="content-wrapper">
            <div className="contact-list">
              <div className="logo">
                <Image src={HuijuClLogo56} alt="huiju-cl-logo" />
              </div>
              <div className="icons-row">
                <IconItem
                  src={IconIn}
                  hoverSrc={IconInHover}
                  alt="icon-in"
                  href="https://www.linkedin.com/company/convertlab%E8%8D%9F%E8%81%9A"
                />
                <IconItem
                  src={IconZhi}
                  hoverSrc={IconZhiHover}
                  alt="icon-zhi"
                  href="https://www.zhihu.com/org/ji-ke-34-46-56"
                />
                <IconItem
                  src={IconToutiao}
                  hoverSrc={IconToutiaoHover}
                  alt="icon-toutiao"
                  href="https://www.toutiao.com/c/user/token/MS4wLjABAAAATplSPBdZ1OjQZW3qVlGPtHAV-rk92uaV1oNtEhwSxMrM6iWA6FhLIHRpZup_Butd/?source=feed"
                />
                <IconItem
                  src={IconBai}
                  hoverSrc={IconBaiHover}
                  alt="icon-bai"
                  qrCode={BaijiaQrCode}
                />
                <IconItem
                  src={IconShipin}
                  hoverSrc={IconShipinHover}
                  alt="icon-shipin"
                  qrCode={ShipinQrCode}
                />
              </div>
              <div className="contact-item-wrapper">
                <h5>联系我们</h5>
                <div className="contact-item">
                  <a href="tel://4001036033">Tel：400-850-9918</a>
                </div>
                <div className="contact-item">
                  <a href="mailto:MKT@convertlab.com">E-mail：MKT@convertlab.com</a>
                </div>
                <div className="contact-item">
                  <span>Add:上海市黄浦区云南南路118号凯腾大厦5楼</span>
                </div>
              </div>
            </div>
            <Col lg={3} md={6} sm={12} xs={24} className="footer-qrcode">
              <div className="qr-code-wrapper">
                <QrItem text="关注微信公众号" bindingId="_cl_qrcode_footer" />
                <QrItem text="扫码咨询" bindingId="_cl_wework_qrcode_footer2_pc" />
              </div>
            </Col>
          </div>
        </Container>
      </div>
      <div className="footer-2 mobile">
        <Container>
          <div className="logo">
            <Image src={HuijuClLogo56} alt="huiju-cl-logo" />
          </div>
          <Col className="footer-qrcode">
            <div className="qr-code-wrapper">
              <QrItem text="关注微信公众号" bindingId="_cl_qrcode_footer" />
              <QrItem text="扫码咨询" bindingId="_cl_wework_qrcode_footer2_mobile" />
            </div>
          </Col>
          <div className="icons-row">
            <IconItem
              src={IconIn}
              alt="icon-in"
              href="https://www.linkedin.com/company/convertlab%E8%8D%9F%E8%81%9A"
            />
            <IconItem
              src={IconZhi}
              alt="icon-zhi"
              href="https://www.zhihu.com/org/ji-ke-34-46-56"
            />
            <IconItem
              src={IconToutiao}
              alt="icon-toutiao"
              href="https://www.toutiao.com/c/user/token/MS4wLjABAAAATplSPBdZ1OjQZW3qVlGPtHAV-rk92uaV1oNtEhwSxMrM6iWA6FhLIHRpZup_Butd/?source=feed"
            />
            <IconItem src={IconBai} alt="icon-bai" qrCode={BaijiaQrCode} isMobile />
            <IconItem src={IconShipin} alt="icon-shipin" qrCode={ShipinQrCode} isMobile />
          </div>
          <div className="contact-item-wrapper">
            <div className="contact-item">
              <a href="tel://4001036033">Tel：400-850-9918</a>
            </div>
            <div className="contact-item">
              <a href="mailto:MKT@convertlab.com">E-mail：MKT@convertlab.com</a>
            </div>
            <div className="contact-item">
              <span>Add:上海市黄浦区云南南路118号凯腾大厦5楼</span>
            </div>
          </div>
        </Container>
      </div>
      {props.current === 'home' && (
        <div className="friendly-links-container">
          <FriendlyLinks />
        </div>
      )}
    </section>
  );
}
